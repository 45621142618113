import React ,{ useState}from 'react';
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './storeDetailsPage.scss'
import StoreDetails from './StoreDetails';

const StoreDetailsPage = () => {
    const [inactive, setInactive] =useState(true)
    return (
        <div className='Root'>
            <Sidebar onCollapse={(inactive) => {
                // console.log("in home inactive",inactive);
                setInactive(inactive)
            }} />
            <div className={`Root__container ${inactive ? 'inactive' : ''}`}>
                <Navbar />
                <StoreDetails/>
                
            </div>
        </div>
    )
}

export default StoreDetailsPage