import React ,{ useState}from 'react';
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import SubscriptionPlans from './SubscriptionPlans'
import './planPage.scss'
const PlanPage = () => {
    const [inactive, setInactive] =useState(true)
    return (
        <div className='orderRoot'>
            <Sidebar onCollapse={(inactive) => {
                // console.log("in home inactive",inactive);
                setInactive(inactive)
            }} />
            <div className={`orderRoot__container ${inactive ? 'inactive' : ''}`}>
                <Navbar />
                <SubscriptionPlans />
            </div>
        </div>
    )
}

export default PlanPage