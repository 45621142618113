import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        total: 0,

    },
    reducers: {
        addProduct: (state, action) => {
            const { _id, productID, productName, unitPrice, qty, image, productWeight } = action.payload;

            const existingProduct = state.products.find(
                (product) => product._id === _id
            );
            if (existingProduct) {
                existingProduct.qty += qty;
            } else {
                state.products.push({ _id, productID, productName, unitPrice, qty, image, productWeight });
            }
            state.total += unitPrice * qty;
        },
        removeFromCart: (state, action) => {

            const _id = action.payload;
            const productToRemove = state.products.find(
                (product) => product._id === _id
            );
            if (productToRemove) {

                const totalPrice = productToRemove.qty * productToRemove.unitPrice;
                state.total -= totalPrice;
                state.products = state.products.filter(
                    (product) => product._id !== _id
                );
            }
        },

        changeQuantity: (state, action) => {
            const { _id, newQty } = action.payload;
            const productToUpdate = state.products.find(
                (product) => product._id === _id
            );
            if (productToUpdate) {
                const oldQty = productToUpdate.qty;
                const pricePerUnit = productToUpdate.unitPrice;
                const priceDiff = pricePerUnit * (newQty - oldQty);
                productToUpdate.qty = newQty;
                state.total += priceDiff;
            }
        },
        clearCart: (state) => {
            state.products = [];
            state.total = 0;
        }


    },
});

export const { addProduct, removeFromCart, changeQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
