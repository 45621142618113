import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import './App.css';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Inactive from './Context/SidebarInactive';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from 'jwt-decode';
import { decodeToken } from "./redux/apiCalls";
import { logout } from "./redux/sellerRedux";
import HomePage from "./pages/HomePage/HomePage";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/ForgotPassword/ChangePassword";
import SubscriptionPlans from "./pages/SubscriptionPlans/SubscriptionPlans";
import PlanPage from "./pages/SubscriptionPlans/PlanPage";
import StoreDetails from "./pages/Settings/StoreDetailsPage";

const Dashboard = lazy(() => import('./pages/sellerDashboard/SellerDashboard'));
const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/login/Login'));
const Register = lazy(() => import('./pages/SellerRegistration/SellerRegistration'));
const Orders = lazy(() => import('./pages/Order/Orders'));
const SingleOrder = lazy(() => import('./pages/Order/SingleOrder'));
const Users = lazy(() => import('./pages/User/Users'));
const Store = lazy(() => import('./pages/Store/Store'));
const Products = lazy(() => import('./pages/Product/Products'));
const SingleProduct = lazy(() => import('./pages/Product/SingleProduct'));
const Customers = lazy(() => import('./pages/Customer/Customers'));
const SingleCustomer = lazy(() => import('./pages/Customer/SingleCustomer'));
const Shipment = lazy(() => import('./pages/Shipment/Shipment'));
const OnlineStore = lazy(() => import('./pages/OnlineStore/OnlineStore'));
const ProductPage = lazy(() => import('./pages/Store/ProductPage'));
const CheckoutPage = lazy(() => import('./pages/Store/CheckoutPage'));

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.seller?.accessToken);
  useEffect(() => {
    if (accessToken) {
      const decodedToken = decodeToken(accessToken);

      if (decodedToken) {
        console.log("SUCCESS");
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        console.log("false");
        dispatch(logout());
        navigate('/login');
      }

    } else {
      navigate('/login');
    }
    setLoading(false);
  }, [accessToken, navigate, dispatch]);

  if (loading) {
    return (
      <Backdrop
        open
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
  return (
    <div className="App">
      <Inactive>
        <Router>
          <Suspense fallback={
            <Backdrop
              open
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path='/reset-password/:token' element={<ChangePassword />} />
              <Route path="/:sellerID" element={<Store />} />
              <Route path="/:sellerID/:productID" element={<ProductPage />} />
              <Route path="/:sellerID/checkout" element={<CheckoutPage />} />
              <Route path="/:sellerID/checkout/:productIDs" element={<CheckoutPage />} />
              <Route path="/subscriptionplans" element={<SubscriptionPlans />} />

              <Route path="/" element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/order/:orderID" element={<SingleOrder />} />
                <Route path="/products" element={<Products />} />
                <Route path="/product/:productID" element={<SingleProduct />} />
                <Route path="/product/new" element={<SingleProduct />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customer/:customerID" element={<SingleCustomer />} />
                <Route path="/shipment" element={<Shipment />} />
                <Route path="/store" element={<OnlineStore />} />

                <Route path="/plans" element={<PlanPage />} />

                <Route path="/settings/storedetails" element={<StoreDetails />} />

              </Route>
            </Routes>
          </Suspense>
        </Router>
      </Inactive>
    </div>
  );
}

export default App;
