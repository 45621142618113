import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
    name: "store",
    initialState: {
        storeID: null,
        storeName: null,
        storeData: null,
        products: [],
        selectedProduct: null,
        cart: [],
        
    },
    reducers: {
        storeAllProducts: (state, action) => {
            state.products = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
        },
        storeData: (state, action) => {
            const { storeID, storeName,_id } = action.payload.store[0];
            state.storeData =action.payload.store[0];
            state.storeID = storeID;
            state.storeName = storeName;
            state._id = _id;

        },
    },
});

export const { storeAllProducts, setSelectedProduct, storeData } = storeSlice.actions;
export default storeSlice.reducer;
