import React, { useEffect, useState, useContext } from 'react';
import ReorderIcon from '@mui/icons-material/Reorder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import './sidebar.scss'
import { useNavigate } from 'react-router-dom';
import { SidebarInactiveContext } from '../../Context/SidebarInactive';
import { logout } from '../../redux/sellerRedux';
import { useDispatch } from 'react-redux';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// import { makeStyles } from "@material-ui/core/styles";
const Sidebar = (props) => {
    // console.log("props is", { ...props });
    // const useStyles = makeStyles((theme) => ({
    //     formControl: {
    //         margin: theme.spacing(1),
    //         minWidth: 120,
    //         backgroundColor: "white",
    //         paddingLeft: 4,
    //         color: "white",
    //     },
    // }));
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const navigate = useNavigate();
    const dispatch = useDispatch()
    // const classes = useStyles();
    let screenWidth = 0;
    let screenHeight = 0;
    let flag = 0;

    // const [inactive, setInactive] = useState(true)
    const { inactive, setInactive } = useContext(SidebarInactiveContext)
    const [users, setUsers] = useState()
    const [userData, setUserData] = useState([])
    const [showSettingsSubMenu, setShowSettingsSubMenu] = useState(false);


    window.onresize = screen;
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
    // console.log("Channel", channel);
    function screen() {
        screenWidth = window.innerWidth;
        screenHeight = window.innerHeight;
        // console.log(screenWidth);
        // console.log(screenHeight);
        if (screenWidth <= 900) {
            setInactive(true)
            setShowSettingsSubMenu(false)
        }
    }





    useEffect(() => {

        props.onCollapse(inactive);
        // console.log("inactive is ", inactive);




    }, [inactive])
    const channel = ''
    return (

        <div className={`sidebar ${inactive ? "inactive" : ""}`}>
            <div className="sidebar__toggle" onClick={() => {
                setShowSettingsSubMenu(false)
                setInactive(!inactive)
            }}>
                {/* <div className="sidebar__toggle" > */}
                <ReorderIcon className='reorder-icon' />
            </div>
            <div className="sidebar__divider"></div>
            <div className="sidebar__menu">

                <div onClick={() => {
                    // console.log("click", screenWidth);

                    if (screenWidth <= 525) {

                        setInactive(true)
                    }

                    navigate({


                        pathname: '/dashboard',

                    });
                }}
                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <DashboardIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Dashboard

                    </div>
                </div>


                <div onClick={() => {
                    if (screenWidth <= 525) {

                        setInactive(true)
                    }
                    // setInactive(inactive)

                    navigate({


                        pathname: '/orders',

                    });
                }}

                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <ShoppingCartIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Orders

                    </div>
                </div>


                <div onClick={() => {
                    if (screenWidth <= 525) {

                        setInactive(true)
                    }
                    // setInactive(inactive)

                    navigate({


                        pathname: '/products',

                    });
                }}

                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <LocalOfferIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Products

                    </div>
                </div>
                <div onClick={() => {
                    if (screenWidth <= 525) {

                        setInactive(true)
                    }
                    // setInactive(inactive)

                    navigate({


                        pathname: '/customers',

                    });
                }}

                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <PersonIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Customers

                    </div>
                </div>


                <div onClick={() => {
                    if (screenWidth <= 525) {

                        setInactive(true)
                    }
                    navigate({


                        pathname: '/shipment',

                    });
                }}

                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <LocalShippingIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Shipment

                    </div>
                </div>
                <div onClick={() => {
                    if (screenWidth <= 525) {

                        setInactive(true)
                    }
                    // setInactive(inactive)

                    navigate({


                        pathname: '/store',

                    });
                }}

                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <StorefrontIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Online Store

                    </div>
                </div>

                <div onClick={() => {
                    console.log("sidebar",inactive)
                    if(inactive){
                        setInactive(false)
                    }

                    setShowSettingsSubMenu(!showSettingsSubMenu); // Toggle submenu visibility
                }}
                    className="sidebar__menu-item">
                    <div className="sidebar__menu-item-icon">
                        <SettingsIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Settings
                    </div>
                </div>
                {showSettingsSubMenu && (
                    <div className="sidebar__submenu">
                        <div onClick={() => {

                            if (screenWidth <= 525) {
                                setInactive(true);
                            }
                            navigate('/settings/storedetails')
                        }}
                            className="sidebar__submenu-item">
                            Store Details
                        </div>
                        <div onClick={() => {
                            if (screenWidth <= 525) {
                                setInactive(true);
                            }
                            navigate('/plans')
                        }}
                            className="sidebar__submenu-item">
                            Plans
                        </div>
                    </div>
                )}

                <div
                    onClick={() => {
                        // firebase.auth().signOut()
                        setInactive(true)
                        // console.log(inactive);
                        dispatch(logout())

                        navigate({


                            pathname: '/login',

                        });
                        // window.location.reload(true)
                    }}



                    className="sidebar__menu-item">

                    <div className="sidebar__menu-item-icon">
                        <LogoutIcon className='mui-style' />
                    </div>
                    <div className="sidebar__menu-item-name">
                        Logout


                    </div>
                </div>



            </div>


        </div >
    )
}

export default Sidebar;
