import React, { useEffect, useState } from 'react';
import './SubscriptionPlans.scss';
import { useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const plans = [
  {
    name: 'Free Plan',
    price: '₹0',
    period: '/ forever',
    features: [
      '1 Product',
      '1 Warehouse',
      'Custom shipping options',
    ],
    buttonText: 'Get Started',
    isPopular: false,
  },
  {
    name: 'Starter',
    price: '₹299',
    period: '/ month',
    features: [
      'Everything in Free Plan',
      '5 Products',
      '2 Warehouses',
    ],
    buttonText: 'Choose Plan',
    isPopular: false,
  },
  {
    name: 'Premium',
    price: '₹499',
    period: '/ month',
    features: [
      'Everything in Starter Plan',
      '100 Products',
      'Unlimited Warehouses',
      'Shipping Provider integrations (Delhivery, Shiprocket)',
      'Bulk Order processing',
      'Announcement Bar',
      'Direct Checkout feature',
      'WhatsApp customer support',
      'Order tracking for customers',
    ],
    buttonText: 'Choose Plan',
    isPopular: true, // Highlighted as Most Popular
  },
  {
    name: 'Infinity',
    price: '₹999',
    period: '/ month',
    features: [
      'Everything in Premium',
      'Unlimited Products',
      'Custom Domain',
      'Payment Gateway integration',
      'WhatsApp Business API integration',
    ],
    buttonText: 'Choose Plan',
    isPopular: false,
  },
];

const SubscriptionPlans = () => {
  // const currentPlan = 'Free Plan'; 
  const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
  const [currentPlan, setCurrentPlan] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const response = await sellerRequest.get(`/subscription/${sellerID}`);
        console.log("Plan", response.data);
        setSubscriptionDetails(response.data)
        setCurrentPlan(response.data.plan); // Assuming the API returns { plan: "Free Plan" }
      } catch (error) {
        console.error("Error fetching the subscription plan", error);
      }
    };

    fetchCurrentPlan();
  }, [sellerID]);


  const handlePlanSelection = async (planName) => {
    setLoading(true);


    try {
      if (subscriptionDetails?.message === "Subscription not found.") {

        const response = await sellerRequest.post('/subscription', {
          sellerID,
          plan: planName,
          startDate: new Date(),
          endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 month later
          billingCycle: 'monthly', // assuming monthly for simplicity
          price: plans.find(plan => plan.name === planName).price.replace('₹', ''), // get the price
        });
        console.log("Created new subscription", response.data);


      } else {
        const response = await sellerRequest.put(`/subscription/${sellerID}`, {
          plan: planName,
        });
      }
      setCurrentPlan(planName); // Update the state with the newly selected plan
      setSnackbarMessage('Plan updated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error updating the subscription plan', error);
      setSnackbarMessage('Failed to update the subscription plan.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true); // Show the snackbar after the operation
    }

  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  return (
    <div className="subscription-plans">
      <h1 className="subscription-plans__title">Choose Your Plan</h1>
      <div className="subscription-plans__container">
        {plans.map((plan, index) => (
          <div
            className={`plan-card ${plan.isPopular ? 'plan-card--popular' : ''} ${plan.name === currentPlan ? 'plan-card--current' : ''}`}
            key={index}
          >
            {plan.isPopular && <div className="plan-card__badge">Most Popular</div>}
            <h2 className="plan-card__name">{plan.name}</h2>
            <div className="plan-card__price">
              <span className="plan-card__price-value">{plan.price}</span>
              <span className="plan-card__price-period">{plan.period}</span>
            </div>
            <ul className="plan-card__features">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="plan-card__feature">
                  {feature}
                </li>
              ))}
            </ul>
            <button className="plan-card__button"
              disabled={plan.name === currentPlan}
              onClick={() => handlePlanSelection(plan.name)}
            >
              {plan.name === currentPlan ? 'Current Plan' : plan.buttonText}
            </button>
          </div>
        ))}
      </div>
      {loading && (
        <Backdrop
          open
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default SubscriptionPlans;
