import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './storeDetails.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const StoreDetails = () => {
    const sellerData = useSelector((state) => state.seller.sellerData);
    const [loading, setLoading] = useState(false);
    const [storeData, setStoreData] = useState();

    console.log(sellerData);
    const handleUpdate = () => {
        setLoading(true);
        // Add your update logic here
        setLoading(false);
    };
    useEffect(() => {
        setStoreData(sellerData.store[0])
    }, [])

    return (
        <>
            {storeData &&
                <>
                    <div className="store-details">
                        <h2 className="store-details__title">Store Details</h2>
                        <div className="store-details__form">
                            <div className="store-details__form-group">
                                <h3 className="store-details__label">Store Name</h3>
                                <input
                                    className="store-details__input"
                                    type="text"
                                    id="storeName"
                                    name="storeName"
                                    value={storeData.storeName}
                                    readOnly
                                />
                            </div>
                            <div className="store-details__form-group">
                                <h3 className="store-details__label">Store About</h3>
                                <textarea
                                    className="store-details__input"
                                    id="storeAbout"
                                    name="storeAbout"
                                    value={storeData.storeAbout}
                                    readOnly
                                />
                            </div>

                            <div className="store-details__form-group">
                                <h3 className="store-details__label">GST</h3>
                                <input
                                    className="store-details__input"
                                    type="text"
                                    id="gst"
                                    name="gst"
                                    value={storeData.gst}
                                    readOnly
                                />
                            </div>
                            <button className="store-details__update-button" onClick={handleUpdate}>Update</button>
                        </div>
                        {loading && (
                            <Backdrop
                                open
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                    zIndex: (theme) => theme.zIndex.drawer + 1
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}
                    </div>
                </>}

        </>
    );
};

export default StoreDetails;
