import React from 'react';
import './NavbarHomePage.scss';
import { useNavigate } from 'react-router-dom';
const NavbarHomePage = () => {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };
  const handleSignupClick = () => {
    navigate('/register');
  };
  return (
    <nav className="navbar">
      <div className="navbar__logo">Prodinent</div>
      <div className="navbar__auth-buttons">
        <button className="navbar__button-login" onClick={handleLoginClick}>Login</button>
        <button className="navbar__button-signup" onClick={handleSignupClick}>Signup</button>
      </div>
      
    </nav>
  );
}

export default NavbarHomePage;
