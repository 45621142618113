import { changeUsernameIndex, loginFailure, loginStart, loginSuccess, updateSellerData } from "./sellerRedux";
import { publicRequest, sellerRequest } from "../requestMethods";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const login = async (dispatch, user) => {

  dispatch(loginStart());
  let response = {};
  try {

    const res = await publicRequest.post("/auth/login", user, {
      withCredentials: true // Send cookies with the request
    });

    response = res
    console.log("");
    if (res) {


      dispatch(loginSuccess({ sellerData: res.data }));

      response = { status: "success", message: "" };
    }
  } catch (err) {
    dispatch(loginFailure());
    response = { status: "error", message: "Login failed" };
  }
  return response;
};
export const fetchAndUpdateSellerData = ({ sellerID }) => async (dispatch) => {
  console.log("seller ID", sellerID);
  try {
    const res = await sellerRequest.get(`/seller/find/${sellerID}`, {
      withCredentials: true,
    });

    console.log("Fetched data:", res.data);
    dispatch(updateSellerData({ sellerData: res.data }));

  } catch (error) {
    console.error("Error fetching seller data:", error);
    // Handle error if needed
  }
};

export const decodeToken = (accessToken) => {
  console.log("1");
  try {
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    console.log("Decoded token",decodedToken);

    if (decodedToken.exp < currentTime) {
      return null; // Token has expired
    }

    return decodedToken;
  } catch (error) {
    return null; // Invalid token
  }
};

