import React from 'react';
import './FooterHomePage.scss';

const FooterHomePage = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-homepage">
      <div className="footer-homepage__content">
        <div className="footer-homepage__logo">Prodinent</div>
        <div className="footer-homepage__about">
          <p>
            Prodinent is an e-commerce solution that helps small-scale online sellers establish an active online presence and set up their stores. We offer features such as intuitive product listing, automated order management, and efficient shipping label generation, all while providing a seamless customer experience across devices.
          </p>
        </div>
        <div className="footer-homepage__links">
          <a href="/login" className="footer-homepage__link">Login</a>
          <a href="/register" className="footer-homepage__link">Signup</a>
        </div>
      </div>
      <div className="footer-homepage__copyright">
        &copy; {currentYear} Prodinent. All rights reserved.
      </div>
    </footer>
  );
};

export default FooterHomePage;
